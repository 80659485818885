var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": true,
      "width": "1280",
      "persistent": true,
      "overlay-opacity": 0.9,
      "dark": ""
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.barrier.name) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "text": "",
      "x-small": "",
      "fab": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('LiveFeedWebrtc', {
    attrs: {
      "stream-url": `https://beleptetoadmin.sze.hu/mediamtx/${_vm.barrier.camera_id}/`
    }
  }), _c('v-divider', {
    staticClass: "mb-0 pb-0"
  }), _c('v-card-actions', {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('v-spacer'), !_vm.permanentOpen ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "text": "",
      "align": "start",
      "title": "Kinyitás"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.openEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock-open")]), _vm._v(" Nyitás ")], 1) : _vm._e(), !_vm.permanentOpen && !_vm.barrier.nciom_control_seq_id ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "text": "",
      "align": "start",
      "title": "Tartós nyitás"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.permanentOpenEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock-open-variant")]), _vm._v(" Tartós nyitás ")], 1) : _vm._e(), _vm.permanentOpen ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "text": "",
      "align": "start",
      "title": "Normál üzem időpont"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.normalOperationDatetimeEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem ütemzése ")], 1) : _vm._e(), _vm.permanentOpen ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "align": "start",
      "title": "Normál üzem azonnal"
    },
    on: {
      "click": function ($event) {
        return _vm.sequentialInvoker({
          fn: _vm.normalOperationNowEventHandler,
          args: [_vm.barrier]
        }, {
          fn: _vm.fetchBarrierDetails
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-lock")]), _vm._v(" Normál üzem azonnal ")], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }